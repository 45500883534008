import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db, doc, deleteDoc, auth, ref, getDownloadURL, storage } from "./firebase";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { DateTime } from "luxon";
import "./Comment.css"

function Comment({id, uid, name, message, timestamp, collection}) {

    const [expandMessage, setMessage] = useState();
    const [show2, setShow2] = useState(false);

    async function deleteComment(collection, id){
        await deleteDoc(doc(db, collection, id));
    }

    function handleOnClick(expandMessage) {
        setShow2(true)
        setMessage(expandMessage)
    }


    const [startImage, getImage] = useState();
    useEffect(() => {
        getDownloadURL(ref(storage, `images/${auth.currentUser.uid}`))
        .then((url) => {
            //const img = document.getElementById('userImage');
            //img.setAttribute('src', url);
            console.log(url)
        });
    }, [startImage])

    return (
        <>
            <div className="commentWrap">
                <div className="left">
                    <div className="userPhoto"><img id="userImage" src={"https://firebasestorage.googleapis.com/v0/b/verseful-main.appspot.com/o/images%2F" + uid + "?alt=media"} /></div>
                </div>
                <div className="right">
                    <div className="userName">
                        {name ? name : `Tester`} 
                        <span className="commentTimestamp">
                            {DateTime.fromJSDate(timestamp && timestamp.toDate()).toRelative()}
                        </span>
                        <div className="commentActions"></div>
                    </div>
                    <div className="userComment">{message}</div>
                
                    <p>{uid == auth.currentUser.uid 
                        ? 
                        <Link to="" className="text-secondary small text-decoration-none" onClick={(e) => { 
                            
                            const confirmBox = window.confirm(
                                "Do you really want to delete this comment?"
                            )
                            if (confirmBox === true) {
                                deleteComment(collection, id)
                            }
                        
                        }}>
                            Delete
                        </Link> 
                        : 
                        <>
                        <Link to="" onClick={(e) => handleOnClick(expandMessage)} className="text-secondary small text-decoration-none">Respond</Link>
                        {/*<Link to="" className="text-secondary small float-start text-decoration-none" onClick={(e) => {
                            
                            const confirmBox = window.confirm(
                                "Are you sure you want to flag this item?"
                            )
                            if (confirmBox === true) {
                                
                            }
                        }}>
                            Flag
                    </Link> */}
                        </>
                    }</p>
                </div>

            </div>
            
            <Offcanvas style={{width: `60%`, height: `100%`}} placement={'end'} show={show2} onHide={(e) => setShow2(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <p>{message}</p>
                <small>Working on this... I can only do so many things at a time...</small>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Comment