import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./prompt.css"
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { auth, onAuthStateChanged } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Settings from "./Settings";
import Login from "./Login";
import Week from "./Week";
import Today from "./Today";
import Home from "./Home";
import Signup from "./Signup";
import Discuss from "./Discuss";
import Content from "./Content";
import Support from "./Support";
import Ambassador from "./Ambassador";
import Forgot from "./Forgot";
import V1 from "./V1";

function PrivateRoute() {

    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
  
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        setCurrentUser(user)
        setPending(false)
      });
    }, []);

    if(pending){
        return <div className="pt-5 mt-5 text-center"><p>Loading...</p></div>
    }

    return currentUser ? <Outlet /> : <Navigate to="/home" />;
};


function App() {

  return (
    <> 
        <BrowserRouter>
            <Routes>
            <Route path='/' element={<V1/>}/>
            </Routes>
        </BrowserRouter>
    </> 
  );
}

export default App;