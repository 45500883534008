import { firebase, initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, onSnapshot, updateDoc, increment, collection, query, doc, where, getDocs, getDoc, limit, orderBy, loadBundle, namedQuery, addDoc, setDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL, getBlob } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyChjG9NLzT_VtrwB7LLqzA3PiJmi5rqQkc",
    authDomain: "verseful-main.firebaseapp.com",
    projectId: "verseful-main",
    storageBucket: "verseful-main.appspot.com",
    messagingSenderId: "508494782933",
    appId: "1:508494782933:web:1bf5a45571ab2cdbd45d5c",
    measurementId: "G-4KBWTNL3EL"
});

const auth = getAuth();
const db = getFirestore(firebaseApp);
const functions = getFunctions();
const analytics = getAnalytics();
const storage = getStorage();

const signIn = async (email, password) => {

  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    const user = userCredential.user;
  })
  .catch((err) => {
    if(err.code === 'auth/invalid-email'){
      alert("Invalid email or password");
    } else if (err.code === 'auth/wrong-password'){
      alert("Invalid password")
    } else {
      alert(err.code)
    }
  })

};

const register = async (first, last, email, password, phone, month, day, year) => {

  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    setDoc(doc(db, "users", user.uid), {
      first: first,
      last: last,
      email: email,
      phone: phone,
      birthday: {month: month, day: day, year: year},
      joined: serverTimestamp(),
    })
  })
  .catch((error) => {
    alert(error)
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  
};

const sendResetEmail = async (email) => {
  sendPasswordResetEmail(auth, email)
  .then(() => {
      alert("Please check your inbox.")
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    alert(error.message)
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
};

const logout = () => {
  auth.signOut();
};  

export {
  auth,
  analytics,
  onAuthStateChanged,
  functions,
  db,
  storage,
  increment,
  ref,
  getDownloadURL,
  uploadBytes,
  addDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  deleteDoc,
  serverTimestamp,
  getDoc,
  getBlob,
  loadBundle,
  namedQuery,
  doc,
  collection,
  where,
  query,
  limit,
  orderBy,
  signIn,
  register,
  sendResetEmail,
  logout,
};
