import React, { useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas'
import "./Today.css"
import Chat from './Chat';
import { BsHeart, BsHeartFill, BsChat, BsChatFill, BsChevronRight } from "react-icons/bs";

function V1() {

    const [topic, setTopicForChat] = useState(null)
    const [showChat, setShowChat] = useState(false);
    function handleOnClick(topic) {
        setShowChat(true)
        setTopicForChat(topic)
    }

    return (
        <div>
            <div style={{marginTop: `30vh`, textAlign: `center`, fontSize: `0.8em`}}>
                <p>Verseful
                    <br/>
                    <small className="text-secondary">(chat module for testing and training)</small>
                    <br/>
                    <small className="text-secondary fw-light">v0.01</small>
                </p>
            </div>
            <Link to="" onClick={(e) => handleOnClick()} className="chatWrapper text-decoration-none">< BsChat /></Link>
            <Offcanvas className="chatHolder" placement={'end'} show={showChat} onHide={(e) => setShowChat(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Verseful Messenger</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="msgrChat">
                    <Chat topic={topic}/>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default V1;