import React, { useEffect, useRef, useState } from 'react';
import { auth } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import ChatBottomManager from './ChatBottom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Chat.css';
import "./prompt.css"


function Chat(passedTopic) {

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {

      if(passedTopic.topic){
        setContent(old => [...old, {message: passedTopic.topic, type: 'right'}]);
        doWork(passedTopic.topic)
        setLoading(true);
      }

      auth.onAuthStateChanged((user) => {
        setCurrentUser(user)
      });

  }, []);


  const dummy = useRef();
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [content]);


  //Submit Form//
  const [formValue, setFormValue] = useState('');
  const sendMessage = async (e) => {
    e.preventDefault();
    setContent(old => [...old, {message: formValue, type: 'right'}]);
    setLoading(true);
    setFormValue('');
    doWork(formValue)
  }

  //Get back data//
  function doWork(formValue){
    const functions = getFunctions();
    const getResponse = httpsCallable(functions, 'custom');
    getResponse({ Body: formValue })
      .then((result) => {
      setLoading(false);
      const data = result.data;
      setContent(old => [...old, {message: data.text, verse: data.verse, gptFeeling: data.gptFeeling, gptKeywords: data.gptKeywords, gptSafe: data.gptSafe, type: 'left'} ])
      })
      .catch((error) => {
      setLoading(false);
      setContent(old => [...old, {message: 'Oops, there was a problem.', gptFeeling: null, type: 'left'} ])
      const code = error.code;
      const message = error.message;
      const details = error.details;
    });
    
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }


  return (
    <>
    
          {passedTopic.topic ?

          <>
          </>
          
          :
          <>
          <div className='msg left-msg pt-3'>
          <div className="msg-bubble">
          Let's find you a Bible verse!
          </div>
          </div>
          <div className='msg left-msg pt-3'>
          <div className="msg-bubble">
          Type a message about how you are feeling or what's going on in your life.
          </div>
          </div>
          </>
          }

        <MessagesList messages={content} />
        {loading == true ? 
            <div className='msg left-msg pt-3'>
            <div className='steps'>Working on finding you a verse...</div>
            </div> 
            : 
            <></>
        }
        
        <span ref={dummy}></span>

        <div className="inputWrapperChat">
            <ChatBottomManager setContent={setContent} setLoading={setLoading} loading={loading}/>
            <form onSubmit={sendMessage} className="msger-inputarea">
              <input value={formValue} onChange={(e) => setFormValue(e.target.value)} name="Body" className="msger-input" maxLength="100" placeholder="What's on your mind today?" />
              <button type="submit" className="msger-send-btn" disabled={!formValue}><FontAwesomeIcon className="text-white" style={{fontSize: `2.5em`}} icon={faPaperPlane} /></button>
            </form>
        </div>

    </>
  )
}


function MessagesList(props) {
  const [message, setMessage] = useState();
  const [verse, setVerse] = useState();
  const [show2, setShow2] = useState(false);
  function handleOnClick(message, verse) {
    setShow2(true)
    setMessage(message)
    setVerse(verse)
  }
  const messages = props.messages;
  const messageItems = messages.map((message, index) =>
    <div className={`msg ${message.type}-msg pt-3`} key={index}>
      <div className="msg-bubble">
        <p>{message.message}</p>
        {message.type  ===  'left' ? <p>-{message.verse}<br/><br/><a href="#" className='text-decoration-none'>Link to more...</a></p> : <></>}
        {message.gptFeeling ? 
          <>
          <p style={{fontSize: `0.4em`, marginTop: `15px`}}>
            (General feeling: {message.gptFeeling})
          </p> 
          <p style={{fontSize: `0.4em`}}>
            (Keywords: {message.gptKeywords})
          </p> 
          <p style={{fontSize: `0.4em`}}>
            (Saftey: {message.gptSafe})
          </p> 
          </>
          : 
          <></>
        }
      </div>
    </div>
  );
  return (
    <>
    {messageItems}
  </>
  );
}

export default Chat;