import React, {useState, useEffect} from 'react'
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, getDoc, doc, loadBundle, namedQuery } from './firebase';
import { Link } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
const firebaseConfig = {
  apiKey: "AIzaSyChjG9NLzT_VtrwB7LLqzA3PiJmi5rqQkc",
  authDomain: "verseful-main.firebaseapp.com",
  projectId: "verseful-main",
  storageBucket: "verseful-main.appspot.com",
  messagingSenderId: "508494782933",
  appId: "1:508494782933:web:1bf5a45571ab2cdbd45d5c",
  measurementId: "G-4KBWTNL3EL"
};


function ChatBottomManager({setContent, setLoading, loading}) {

  const [prompts, setPrompts] = useState([]);
  const [promptLoading, setPromptLoading] = useState(true);

  useEffect(() => {
    fetchData();
    setLoading(false);
  },[]);

  const fetchData = () => {
    async function getOpeningPrompt(){
      const promptRef = doc(db, 'prompts', 'opening');
      const promptSnap = await getDoc(promptRef);
      if (promptSnap.exists()) {
        setPrompts(promptSnap.data());
        setPromptLoading(false);
      } else {
      }
    }
    getOpeningPrompt()
  }


  function doTheWork(value, keyword) {
    setContent(old => [...old, {message: value, type: 'right'} ])
    const functions = getFunctions();
    setLoading(true);

    if(keyword === 'random'){

      const firebaseApp = firebase.initializeApp(firebaseConfig);
      const db = firebaseApp.firestore();

      async function fetchFromBundle() {

        function getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        }

        const resp = await fetch('/getRandom')
        await db.loadBundle(resp.body);
        const query = await db.namedQuery('random-verses-query');
        const randomSnap = await query.get({ source: 'cache' });
        const verseData = randomSnap.docs.map(doc => doc.data());
        var count = verseData.length;
        var randomNumber = getRandomInt(count);

        if (randomSnap.empty) {
            console.log("Nothing cached")
        }

        setTimeout(() => {
          setContent(old => [...old, {message: verseData[randomNumber].niv, verse: verseData[randomNumber].fullVerse, type: 'left'} ])
          setLoading(false);
        }, 500);

      }

      fetchFromBundle()
      return;

    }

    const getPrompt = httpsCallable(functions, 'suggested');
    getPrompt({ Body: keyword })
      .then((result) => {
        const data = result.data;
        setContent(old => [...old, {message: data.text, verse: data.verse, type: 'left'} ])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        const code = error.code;
        const message = error.message;
        const details = error.details;
      });
  }


  return (
    <>
      { promptLoading ? (
         <div className="pt-2">
        </div>
      ) : (
        <>
        <div className="promptSectionChat pt-2">
          <button disabled={loading} className="promptItemChat" onClick={ ()=> { doTheWork(prompts[0].prompt, prompts[0].keyword) }}>{prompts[0].prompt}</button>
          <button disabled={loading} className="promptItemChat" onClick={ ()=> { doTheWork(prompts[1].prompt, prompts[1].keyword) }}>{prompts[1].prompt}</button>
          {//<button disabled={loading} className="promptItemChat" onClick={ ()=> { doTheWork(prompts[2].prompt, prompts[2].keyword) }}>{prompts[2].prompt}</button>
}
        </div>
        </>
      )}
      </>
  )
}

export default ChatBottomManager